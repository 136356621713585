<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Modifier une zone",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Modifier une zone",
      items: [
        {
          text: "Infrastructures",
          href: "/",
        },
        {
          text: "Zones",
          href: "/",
        },
        {
          text: "Modifier une zone",
          active: true,
        },
      ],
      zoneTypes: [
        {
          id: "vip",
          name: "VIP",
        },
        {
          id: "missionary",
          name: "MISSIONNAIRE",
        },
        {
          id: "ordinaire",
          name: "ORDINAIRE",
        },
      ],
      zoneForm: {
        uuid: "",
        zoneName: "",
        zoneAdr: "",
        zoneType: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    zoneForm: {
      zoneName: { required, maxLength: maxLength(64) },
      zoneType: { required },
    },
  },
  methods: {
    fetchSingleZoneDetails() {
      var _this = this;
      this.$http
        .post(
          "/infrastructure/zones/getSingleZoneDetails/" + this.zoneForm.uuid
        )

        .then((res) => {
          var status = res.data.original.status;
          var zone = res.data.original.data.zone;
          switch (status) {
            case 200:
              _this.zoneForm.zoneName = zone.name;
              _this.zoneForm.zoneType = zone.type;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.warning(error.message);
        })
        .finally(() => {});
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
        var router = this.$router;

        this.$http
          .post("/infrastructure/zones/update", this.zoneForm)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                router.push({ name: "base.infrastructures.zones" });
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
  },

  mounted() {
    this.zoneForm.uuid = this.$route.params.uid;
    this.fetchSingleZoneDetails();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Désignation</label>
                    <input
                      id="deptName"
                      v-model="zoneForm.zoneName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Mark"
                      :class="{
                        'is-invalid': submitted && $v.zoneForm.zoneName.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.zoneForm.zoneName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.zoneForm.zoneName.required"
                        >Le nom est obligatoire.</span
                      >
                      <span v-if="!$v.zoneForm.zoneName.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Type</label>
                    <select
                      id="projectname"
                      v-model="zoneForm.zoneType"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.zoneForm.zoneType.$error,
                      }"
                      placeholder=""
                    >
                      <option value=""></option>
                      <option
                        v-for="zoneType in zoneTypes"
                        v-bind:value="zoneType.id"
                        v-bind:key="zoneType.id"
                      >
                        {{ zoneType.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.zoneForm.zoneType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.zoneForm.zoneType.required"
                        >Le Type est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary"
                type="submit"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
